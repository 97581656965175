<template>
    <div v-if="orders">
        <n-navbar />
        <main style="padding-bottom: 0px;">
            <div class="bg-lightwhite" style="padding-top: 44px;">
                <ul class="order-list-menu select-none">
                    <li v-for="option in filterOption" :key="option.id" @click="statusFilter = option.id" :class="{ active: statusFilter === option.id }">
                        {{ option.label }}
                    </li>
                </ul>
                <div v-if="computedOrders.length < 1" class="w-100 text-center p-3" style="height: calc(100vh - 100px);">
                    <div class="text-dark-warm-grey font-weight-500 mt-2">沒有任何訂單</div>
                </div>
                <section v-else class="bg-lightwhite p-3"  style="height: calc(100vh - 100px);">
                    <div class="px-3 pb-3 flex-element center between">
                        <div class="font-15 text-dark-wram-grey">全部 {{ orders.length }} 筆</div>
                    </div>
                    <ul>
                        <li class="order-card" v-for="(order, orderIndex) in computedOrders" :key="order.id" :id="order.id" :class="{ 'mb-2': orderIndex !== computedOrders.length - 1 }" @click.prevent="$router.push(`/${shop.id}/order/${order.id}`)">
                            <div class="flex-element center between">
                                <div class="flex-element">
                                    <div class="center-element middle img-container bg-lightwhite">
                                        <img src="@/assets/icons/ic-delivery.svg" width="24" height="24" v-if="order.method == 'DELIVERY'" />
                                        <img src="@/assets/icons/ic-pickup.svg" width="24" height="24" v-else />
                                    </div>
                                    <div class="center-element middle pl-3">
                                        <div class="font-17 text-black font-weight-500 line-height-15">{{ order.uniNum }}{{ order.source == 'BYOD' ? 'M' : '' }}</div>
                                        <div class="font-15 text-light-wram-grey line-height-15">{{ renderTimeText(order) }}</div>
                                    </div>
                                </div>
                                <div class="flex-element font-size-0 text-right">
                                    <div class="center-element middle">
                                        <div class="font-19 font-weight-700 text-black line-height-12">${{ order.grandTotal }}</div>
                                        <div class="font-15 text-light-wram-grey line-height-15">{{ order.products.length }} 項</div>
                                    </div>
                                    <div class="center-element middle pl-1 font-size-0 line-height-1">
                                        <img src="@/assets/icons/ic-right-grey.svg" width="24" height="24" />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    data() {
        return {
            statusFilter: 'PENDING',
            filterOption: [
                { id: 'PENDING', label: '待接單' },
                { id: 'RECEIVED', label: '已接單' },
                { id: 'PACKED', label: '待取餐' },
                { id: 'COMPLETED', label: '已取餐' },
                { id: 'VOID', label: '已取消' },
            ],
        }
    },

    async created() {
        await this.$store.dispatch('bindCollectionByCustomer', { col: 'orders', customerId: localStorage.getItem(`${this.shop.merchantId}-customerId`) })
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
            orders: (state) => state.orders,
        }),

        computedOrders() {
            return _.filter(this.orders, (order) => order.status === this.statusFilter)
        },
    },

    methods: {
        renderTime(time) {
            return moment.unix(time).format('MM/DD HH:mm')
        },

        renderTimeText(order) {
            switch (order.status) {
                case 'PENDING':
                    if (order.type == 'ASAP') return '越快越好 ' + this.renderTime(order.createdAt)
                    return '預約 ' + this.renderTime(order.reservationAt)
                case 'RECEIVED':
                    return '接單時間 ' + this.renderTime(order.receivedAt)
                case 'PACKED':
                    return '訂單完成 ' + this.renderTime(order.packedAt)
                case 'COMPLETED':
                    return '餐點完成 ' + this.renderTime(order.completedAt)
                
            }
        },
    },
}
</script>
